// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { Socket } from "lib/phoenix";
import phoenixTypes from "types/phoenix";
import { API_VERSION } from "./gf-api/api-util";

export type Result<T, E = any> =
  | { ok: true; value: T }
  | { ok: false; error: E };

export function pushToChannel<T, E = any>(
  channel: phoenixTypes.Channel,
  event: string,
  payload: any = {}
): Promise<Result<T, E>> {
  return new Promise((resolve) => {
    channel
      .push(event, payload)
      .receive("ok", (value: T) => resolve({ ok: true, value }))
      .receive("error", (error: E) => {
        console.warn(`Error in ${event}:`, error);
        resolve({ ok: false, error });
      });
  });
}

export function joinChannel<T = any, E = any>(
  channel: phoenixTypes.Channel
): Promise<Result<T, E>> {
  return new Promise((resolve) => {
    channel
      .join()
      .receive("ok", (value: T) => resolve({ ok: true, value }))
      .receive("error", (error: E) => {
        console.warn("Error joining channel:", error);
        resolve({ ok: false, error });
      });
  });
}

export function connectWebSocket(
  url: string,
  jwt: string,
  groupSlug: string
): phoenixTypes.Socket | null {
  if (!jwt) {
    throw new Error("No jwt is set");
  }

  if (typeof window === "undefined") return null;
  const socket = new Socket(url, {
    params: { jwt, group: groupSlug, v: API_VERSION },
  }) as any;
  socket.connect();
  return socket;
}
