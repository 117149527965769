import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import phoenixTypes from "types/phoenix";
import { Conversation, ExtendedMessage } from "types/messaging";
import { parseDate } from "lib/date";
import { Map } from "immutable";
import WebSocketStore from "./WebSocketStore";
import JwtStore from "./JwtStore";
import { loginToContinue } from "lib/auth";

function markMessagesAsRead(
  messages: ExtendedMessage[],
  lastReadMessageId: string | null
) {
  let updatedMessages = [] as ExtendedMessage[];
  let foundRead = false;
  let message;
  for (let i = messages.length - 1; i >= 0; i--) {
    message = messages[i];
    if (message.id === lastReadMessageId) {
      foundRead = true;
    }
    message = { ...message, read: foundRead };
    updatedMessages = [message, ...updatedMessages];
  }

  return updatedMessages;
}

export function parseConversation(data: any): Conversation {
  let { mostRecentReceivedAt } = data;
  mostRecentReceivedAt = parseDate(mostRecentReceivedAt);
  let lastReadMessage = data.lastReadMessage;
  if (lastReadMessage) {
    lastReadMessage = parseMessage(lastReadMessage);
  }
  return { ...data, mostRecentReceivedAt, lastReadMessage };
}

export function parseMessage(data: any): ExtendedMessage {
  const sentAt = parseDate(data.sentAt);
  return { elapsed: null, read: false, ...data, sentAt };
}

export default class ChannelStore {
  conversations: Conversation[] | null;
  jwtStore: JwtStore;
  memberChannel: phoenixTypes.Channel | null;
  messages: Map<string, ExtendedMessage[]>;
  startedMemberChannel: boolean;
  webSocketStore: WebSocketStore;

  constructor(webSocketStore: WebSocketStore, jwtStore: JwtStore) {
    this.conversations = null;
    this.jwtStore = jwtStore;
    this.memberChannel = null;
    this.messages = Map();
    this.startedMemberChannel = false;
    this.webSocketStore = webSocketStore;

    makeObservable(this, {
      conversations: observable,
      messages: observable,
      setConversations: action,
      unreadCount: computed,
    });
  }

  getWebSocket() {
    return this.webSocketStore.getWebSocket();
  }

  async loadMemberChannelAndConversations() {
    await this.getMemberChannel();
    if (this.memberChannel) {
      this.loadConversations();
    }
  }

  async getMemberChannel() {
    if (typeof window === "undefined") return null;

    if (!this.startedMemberChannel) {
      this.startedMemberChannel = true;
      const socket = this.getWebSocket();
      if (!socket) {
        this.startedMemberChannel = false;
        throw new Error("getMemberChannel: No socket set");
      }

      const claims = this.jwtStore.validClaims;
      if (!claims) {
        this.startedMemberChannel = false;
        throw new Error("No claims found");
      }
      const sub = claims.sub;
      if (!sub) {
        this.startedMemberChannel = false;
        throw new Error("No valid claims found");
      }
      // This works either with a MemberId or LinkedAccountId
      const channelName = `member:${sub}`;
      this.memberChannel = socket.channel(channelName, {});

      this.memberChannel.join().receive("error", (resp: any) => {
        console.warn("Unable to join", resp);
      });

      this.memberChannel.on("message", (encoded: any) => {
        const message = parseMessage(encoded);
        this.appendMessageForChannel(message);

        let channel =
          this.conversations &&
          this.conversations.find((ch) => ch.id === message.conversationId);
        if (!channel) {
          this.loadConversations();
        }
      });

      this.memberChannel.on("reload", () => {
        if (typeof window !== "undefined") {
          window.location.reload();
        }
      });

      this.memberChannel.on("logout", () => {
        const jwt = this.jwtStore.jwtValue;
        if (jwt) {
          loginToContinue();
        }
      });
    }

    return this.memberChannel as phoenixTypes.Channel;
  }

  loadConversationMessages(conversationId: string): Promise<boolean> {
    if (!this.memberChannel) {
      return Promise.resolve(false);
    }

    const setMessagesForConversation = (
      conversationId: string,
      messages: any
    ) => {
      this.setMessagesForConversation(conversationId, messages);
    };

    const memberChannel = this.memberChannel;

    return new Promise<boolean>((resolve) => {
      (memberChannel as phoenixTypes.Channel)
        .push("getConversationMessages", { conversationId })
        .receive("ok", (resp: any) => {
          const { conversationId, lastReadMessageId } = resp;
          let messages = resp.messages.map(parseMessage);
          const updatedMessages = markMessagesAsRead(
            messages,
            lastReadMessageId
          );
          setMessagesForConversation(conversationId, updatedMessages);
          resolve(true);
        })
        .receive("error", () => {
          resolve(false);
        });
    });
  }

  setConversations(conversations: Conversation[]) {
    this.conversations = conversations;
    this.messages = this.conversations.reduce(
      (
        messages: Map<string, ExtendedMessage[]>,
        conversation: Conversation
      ) => {
        let initialMessages = messages.get(conversation.id);

        if (conversation.lastReadMessageId && initialMessages) {
          const updatedMessages = markMessagesAsRead(
            initialMessages,
            conversation.lastReadMessageId
          );
          return messages.set(conversation.id, updatedMessages);
        } else {
          return messages;
        }
      },
      this.messages
    );
  }

  putChannelMessageAsRead(channelId: string, lastReadMessageId: string) {
    let messages = this.getMessagesForConversation(channelId);
    if (!messages) {
      return;
    }
    messages = markMessagesAsRead(messages, lastReadMessageId);
    this.setMessagesForConversation(channelId, messages);
  }

  get unreadCount() {
    if (!this.conversations) return 0;
    return this.conversations.reduce<number>(
      (count: number, ch: Conversation) =>
        count + this.getUnreadCountForConversation(ch.id),
      0
    );
  }

  setMessagesForConversation(
    conversationId: string,
    messages: ExtendedMessage[]
  ) {
    runInAction(() => {
      this.messages = this.messages.set(conversationId, messages);
    });
  }

  appendMessageForChannel(message: ExtendedMessage) {
    let messages = this.messages.get(message.conversationId);

    runInAction(() => {
      if (messages) {
        const extendedMessages = [...messages, message];
        this.messages = this.messages.set(
          message.conversationId,
          extendedMessages
        );
      }

      const unreadCount = message.conversationUnreadCount;
      if (unreadCount !== undefined && this.conversations) {
        this.conversations = this.conversations.map((ch) => {
          if (ch.id === message.conversationId) {
            return {
              ...ch,
              unreadCount: unreadCount || ch.unreadCount,
            };
          }
          return ch;
        });
      }
    });
  }

  getMessagesForConversation(
    conversationId: string
  ): ExtendedMessage[] | undefined {
    return this.messages.get(conversationId);
  }

  getUnreadCountForConversation(conversationId: string): number {
    const messages = this.getMessagesForConversation(conversationId);
    if (!messages) {
      if (this.conversations) {
        const conversation = this.conversations.find(
          (c) => c.id === conversationId
        );
        if (conversation) {
          return conversation.unreadCount;
        }
      }

      return 0;
    }

    return messages.reduce(
      (count, message) => (message.read ? count : count + 1),
      0
    );
  }

  loadConversations(): Promise<boolean> {
    const { memberChannel } = this;
    if (!memberChannel) throw new Error("memberChannel not set");

    const setConversations = (conversations: Conversation[]) => {
      this.setConversations(conversations);
    };

    return new Promise((resolve) => {
      memberChannel
        .push("getConversations", {})
        .receive("ok", (resp: any) => {
          const rawConversations = resp.conversations as any[];
          const conversations = rawConversations.map(parseConversation);
          setConversations(conversations);
          resolve(true);
        })
        .receive("error", () => {
          resolve(false);
        });
    });
  }
}
