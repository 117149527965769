import { observer } from "mobx-react-lite";
import Link from "next/link";
import { useNavItem } from "hooks/content";
import { useMember } from "hooks";
import { DEFAULT_LOGGED_OUT_PRIMARY_NAV_ITEMS } from "../constants";
import EditableSection from "components/contentEditing/EditableSection";
import { ContentBaseType } from "types/content";
import { filterItemsForLoggedInStatus } from "lib/nav";
import { Fragment, useEffect } from "react";
import { NavItem, NavigationPurpose } from "types/nav";
import { CountBadge } from "components/ui";
import UserMenuButton from "./UserMenuButton";
import clsx from "clsx";
import MessageIcon from "components/icon/MessageIcon";
import { ChatBubbleLeftRightIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { Menu, MenuButton, MenuItems } from "@headlessui/react";
import MegaphoneIcon from "components/icon/MegaphoneIcon";
import LinkMenuItem from "components/ui/LinkMenuItem";
import nextConfigFn from "next/config";
import { useChannelStore, useHamburgerStore, useLoginModalStore } from "stores";
interface Props {
  accountMenuInline?: boolean;
  className?: string;
  navMenuId?: string;
  navMenuSlug?: string;
  parentTag?: string;
  purpose?: NavigationPurpose;
  style?: any;
}
const nextConfig = nextConfigFn();
const {
  forumEnabled
} = nextConfig.publicRuntimeConfig;
function Navigation(props: Props) {
  const {
    className,
    navMenuId,
    navMenuSlug,
    parentTag,
    purpose,
    style
  } = props;
  const {
    getNavMenu,
    getNavMenuById
  } = useNavItem();
  let navMenu = navMenuId ? getNavMenuById(navMenuId) : undefined;
  if (!navMenu && navMenuSlug) {
    navMenu = getNavMenu(navMenuSlug);
  }
  const member = useMember();
  let editById: undefined | {
    id: string;
    baseType: ContentBaseType;
  };
  let editBySlug: undefined | {
    slug: string;
    baseType: ContentBaseType;
  };
  const channelStore = useChannelStore();
  const unreadCount = channelStore?.unreadCount ?? 0;
  const store = useHamburgerStore();
  const loginModalStore = useLoginModalStore();
  useEffect(() => {
    if (navMenu && store && purpose === "primary") {
      store.setNavItemId(navMenu.id);
    }
  }, [navMenuId, navMenuSlug]);
  function renderLink(item: NavItem) {
    return <Link href={item.uri} key={item.uri} className={clsx(parentTag, "Link", className, "transition")} data-sentry-element="Link" data-sentry-component="renderLink" data-sentry-source-file="Navigation.tsx">
        {item.label}
      </Link>;
  }
  function onLoginClick(ev: React.MouseEvent) {
    ev.preventDefault();
    loginModalStore?.open();
  }
  function renderItem(item: NavItem) {
    if (item.uri === "/members/account") {
      return <UserMenuButton menuIsInline={purpose !== "primary"} />;
    } else if (item.uri === "/login") {
      return <Link href="/login" className="Link flex items-center gap-2 relative" onClick={onLoginClick}>
          {purpose === "primary" ? <>
              <UserCircleIcon className="w-6 h-6" />
              <span>Login</span>
            </> : <span>Login</span>}{" "}
        </Link>;
    } else if (item.uri === "/members/messages" || item.uri === "/members/communication") {
      // Communications
      if (purpose !== "primary") {
        return <>
            <Link href="/members/announcements" className={clsx("flex items-center gap-2 relative", purpose !== "sidebar" && "")}>
              <span>Announcements</span>
            </Link>
            {forumEnabled && <Link href="/members/forum" className={clsx("flex items-center gap-2 relative", purpose !== "sidebar" && "")}>
                <span>Discussions</span>
              </Link>}
            <Link href={item.uri} className={clsx("flex items-center gap-2 relative", purpose !== "sidebar" && "")}>
              <span>Messages</span>
              {unreadCount > 0 && <CountBadge className={clsx("right-0 top-0", purpose === "sidebar" ? "-translate-y-1" : "absolute translate-x-4")}>
                  {unreadCount}
                </CountBadge>}
            </Link>
          </>;
      }

      // Communications: Primary menu item
      return <Menu>
          <div>
            <MenuButton className="Link">
              <MessageIcon className="w-[1.3rem] h-[1.3rem] translate-y-1" />{" "}
              {unreadCount > 0 && <CountBadge className="absolute translate-x-[0.125rem] -translate-y-6">
                  {unreadCount}
                </CountBadge>}
            </MenuButton>

            {/* Drop-down menu */}
            <MenuItems anchor="bottom" className="gf-customer bg-white border rounded-lg shadow-lg py-2 text-sm">
              <LinkMenuItem href="/members/announcements" label={<span>Announcements</span>} icon={<MegaphoneIcon className="size-4 text-secondary-600" />} />
              {forumEnabled && <LinkMenuItem href="/members/forum" label={<span>Discussions</span>} icon={<ChatBubbleLeftRightIcon className="size-4 text-secondary-600" />} />}
              <LinkMenuItem href="/members/messages" label={<>
                    <span>Chat Messages</span>
                    {unreadCount > 0 && <span>
                        <CountBadge className="">{unreadCount}</CountBadge>
                      </span>}
                  </>} icon={<MessageIcon className="size-4 mt-[0.1rem] text-secondary-600" />} />
            </MenuItems>
          </div>
        </Menu>;
    }
    return renderLink(item);
  }
  if (navMenu) {
    editById = {
      baseType: "nav",
      id: navMenu.id
    };
  } else if (navMenuSlug) {
    editBySlug = {
      baseType: "nav",
      slug: navMenuSlug
    };
  }
  let items: NavItem[];
  if (navMenu) {
    items = filterItemsForLoggedInStatus((navMenu.children as NavItem[]), member?.account?.status, member?.account?.roles).map(item => ({
      uri: item.uri || "/",
      label: item.label,
      settings: item.settings,
      slug: item.slug,
      role: item.settings?.role
    }));
  } else {
    items = DEFAULT_LOGGED_OUT_PRIMARY_NAV_ITEMS.map(raw => ({
      label: raw.text,
      uri: raw.url
    }));
  }
  if (!items || items.length === 0) {
    return null;
  }
  return <EditableSection className={clsx("Navigation", className)} editById={editById} editBySlug={editBySlug} style={style} data-sentry-element="EditableSection" data-sentry-component="Navigation" data-sentry-source-file="Navigation.tsx">
      {items.map(m => <Fragment key={m.uri}>{renderItem(m)}</Fragment>)}
    </EditableSection>;
}
export default observer(Navigation);